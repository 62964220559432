import 'ant-design-vue/dist/antd.css';
import './main.sass';
import Donut from 'vue-css-donut-chart/src/components/Donut';
import {
    Button,
    Card,
    DatePicker,
    Form,
    Image,
    Input,
    Layout,
    List,
    Modal,
    PageHeader,
    Progress,
    Result,
    Select,
    Table,
    Typography,
    Spin,
    Row,
    Col
} from 'ant-design-vue';
import { createApp } from 'vue';
import store from './store';

import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(Select);
app.use(Card);
app.use(Button);
app.use(Layout);
app.use(Input);
app.use(Table);
app.use(PageHeader);
app.use(Progress);
app.use(Form);
app.use(List);
app.use(Modal);
app.use(DatePicker);
app.use(Result);
app.use(Image);
app.use(Typography);
app.use(Spin);
app.use(Row);
app.use(Col);
app.component(Donut.name, Donut);

// Vue.config.productionTip = false

app.mount('#app');
