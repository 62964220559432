<template>
    <div class="login">
        <a-card
            title="Авторизация"
            :bordered="false"
            class="auth-card"
        >
            <a-form
                layout="horizontal"
                :model="formState"
                v-bind="layout"
            >
                <a-form-item
                    required
                    label="Имя"
                    v-bind="fields.name"
                >
                    <a-input
                        v-model:value="formState.name"
                        placeholder="name"
                    >
                        <template #prefix>
                            <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                        </template>
                    </a-input>
                </a-form-item>
                <a-form-item
                    required
                    label="Пароль"
                    v-bind="fields.password"
                >
                    <a-input
                        v-model:value="formState.password"
                        type="password"
                        placeholder="Password"
                    >
                        <template #prefix>
                            <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                        </template>
                    </a-input>
                </a-form-item>
                <a-form-item>
                    <a-button
                        type="primary"
                        :disabled="!formState.name || !formState.password"
                        @click="onSubmit"
                    >
                        Войти
                    </a-button>
                </a-form-item>
            </a-form>
        </a-card>
    </div>
</template>

<script>

import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { reactive } from 'vue';
import router from '@/router';
import { validationRules } from '@/constants';
import { fetcher } from '@/helpers';
import validate from 'validate.js';
import { notification } from 'ant-design-vue';
import browserCookies from 'browser-cookies';
window.huy = browserCookies;

export default {
    name: 'Login',

    components: {
        UserOutlined,
        LockOutlined,
    },
    setup() {
        const formState = reactive({
            name: '',
            password: '',
        });
        const fields = reactive({
            name: {},
            password: {},
        });

        const showError = (field, error) => {
            fields[field] = { validateStatus: 'error', help: error };
        };
        const hideError = field => {
            fields[field] = {};
        };

        const layout = {
            labelCol: { span: 4 },
        };

        const onSubmit = () => {
            fields.name = {};
            fields.password = {};

            const errorName = validate.single(formState.name, validationRules.name);
            const errorPassword = validate.single(formState.password, validationRules.password);

            if (errorName) {
                showError('name', errorName[0]);
            }
            if (errorPassword) {
                showError('password', errorPassword[0]);
            }

            fetcher.post('/api/auth/login', { ...formState }).then(res => {
                notification.success({ message: 'Вы авторизированы' });
                browserCookies.set('token', res?.token || '');
                setTimeout(() => router.push('/administration'), 1000);
            }).catch(err => {
                browserCookies.set('token', '');
                console.error(err.message);
                notification.error({ message: 'Ошибка авторизации' });
            });
        };

        return {
            formState,
            onSubmit,
            layout,
            fields,
            hideError,
        };
    },
};
</script>

<style lang="sass">
.auth-card
    max-width: 500px
    margin-top: 40px
    margin-right: auto
    margin-left: auto
</style>
