<template>
    <div class="policy">
        <h1>Условия использования и Политика конфиденциальности</h1>

        <h2>Договор-оферта #SERVICE_NAME#</h2>

        <p>
            Настоящие условия заключения сделок определяют условия, на которых производится использование сервиса
            #SERVICE_NAME# / #SITE# , предлагаемого ИП "#ФИО#".
        </p>

        <p>
            Исполнитель - физическое лицо, ИП "#ФИО#" (далее именуемого " #SERVICE_NAME#") зарегистрированный на
            территории Российской Федерации и предоставляющий услуги на основе договора-оферты.
        </p>

        <p>
            Заказчик - физическое лицо, согласный пользоваться услугами #SERVICE_NAME# на ресурсах по настоящему
            договору-оферте.
        </p>

        <p>Пользователь - физические лицо, зарегистрированный на ресурсах #SERVICE_NAME#.</p>

        <p>Ресурсы - веб-сайт который относится к работе #SERVICE_NAME#.</p>

        <p>
            Третье лицо - физическое или юридическое лицо, может являться пользователем ресурсами #SERVICE_NAME#. Третье
            лицо может быть представителем Заказчика, Клиента, Пользователя, Исполнителя либо Оператора.
        </p>

        <p>Услуга - аналитические данные и советы, предоставляемые #SERVICE_NAME#.</p>

        <p>Оператор - представитель #SERVICE_NAME#, является сотрудником Исполнителя.</p>

        <p>
            Веб-сайт (Сайт) - сайт #SERVICE_NAME# в сети интернет, защищённый международным законом о Роялти. Является
            ресурсом Исполнителя.
        </p>

        <!--TODO: fix-->
        <p>
            Базовый аккаунт - зарегистрированный аккаунт на ресурсах #SERVICE_NAME# с правом получения общей
            информации.
        </p>

        <!--TODO: fix-->
        <p>
            Платный аккаунт - зарегистрированный аккаунт на ресурсах #SERVICE_NAME#, в соответствии с настоящим договором
            на платной основе, для получения привилегированных Услуг и информаций на ресурсах #SERVICE_NAME#.
        </p>

        <p>Тариф - виды предоставляемой Услуги, на основе фиксированной суммы.</p>

        <p>
            Клиент — физическое лицо, пользователь приложения на платной основе #SERVICE_NAME#. Регистрация пользователя
            #SERVICE_NAME# означает согласие с условиями Пользовательского соглашения и Конфиденциальности.
        </p>

        <p>
            Для всех клиентов #SERVICE_NAME# действуют исключительно настоящее Пользовательское соглашение. Иные условия
            заключения сделок (и договоров) не принимаются.
        </p>
        <h2>1. Доступ к ресурсам / Заключение договора</h2>

        <p>
            1.1. Доступ к использованию бесплатных сервисов #SERVICE_NAME# подразумевает регистрацию, которая происходит
            в момент «клика мыши», либо иных аналогичных действий на сайте.
        </p>

        <p>
            1.2. В момент нажатия кнопки “купить” на сайте #SERVICE_NAME# клиент вступает в договорные отношения с
            #SERVICE_NAME#, которые регулируются настоящим Пользовательским соглашением и условиями
            Конфиденциальности.
        </p>

        <p>1.3. Договорное отношение возникает только после подтверждения заказа со стороны #SERVICE_NAME#.</p>

        <p>
            1.4. Клиент гарантирует #SERVICE_NAME#, что его данные, введенные при заказе, соответствуют
            действительности.
        </p>
        <h2>2. Использование и передача данных</h2>

        <p>2.1. #SERVICE_NAME# обязуется соблюдать требования законодательства о защите персональных данных.</p>

        <p>
            2.2. Вы найдете информацию об использовании персональных данных сервисом #SERVICE_NAME# в Условиях
            Конфиденциальности.
        </p>

        <p>
            2.3. #SERVICE_NAME# управляет фитнес аналитикой в интернете и предоставляет клиенту бесплатную и платную
            информацию. С приобретением подписки клиент получает доступ к закрытой платной информации, которая содержит
            больше ценной информации, чем в бесплатном доступе.
        </p>

        <p>2.4. Все клиенты могут бесплатно использовать открытые ресурсы сайта #SERVICE_NAME#.</p>

        <p>
            2.5. Клиенты также могут пользоваться сайтом в качестве платного пользователя. Перед приобретением платного
            доступа клиент информируется о конкретном платном сервисе и об условиях оплаты. Платежное обязательство
            возникает только после того, как клиент посредством «клика мыши» (или иного аналогичного действия)
            подтверждает заказ подписки и возникающие в указанной связи обязательства по оплате.
        </p>
        <h2>3. Условия сотрудничества</h2>

        <p>
            3.1. #SERVICE_NAME# имеет право наделить третьи лица полномочиями по частичному или полному оказанию услуг.
            Однако это не должно создавать каких-либо неудобств для клиентов.
        </p>

        <p>
            3.2. #SERVICE_NAME# ограничит доступ к данным клиентам, о неправильности или запрещающих действиях (см п.
            6.0) которых станет известно #SERVICE_NAME#.
        </p>

        <p>
            3.3. #SERVICE_NAME# гарантирует доступность сервиса в течение 95% календарного года. В указанный объем не
            включаются остановки сервиса не по вине #SERVICE_NAME#, например, в случае необходимых сервисных работ или
            вследствие непреодолимой силы или технических неполадок интернета.
        </p>
        <h2>4. Аннулирование регистрации, расторжение договора</h2>

        <p>
            4.1. При потере доступа к аккаунту с любого рода платными услугами #SERVICE_NAME# средства за оплаченный
            период не возмещаются.

            4.2. Договор о платном аккаунте, оплаченный банковской картой или платежной системой yookassa, продлевается
            на согласованный срок действия договора, если клиент не объявит о расторжении договора в течение действия
            срока для расторжения договора (cм. п. 4.4) до истечения срока действия договора, в соответствии с пунктом
            11.1. При продлении договора, списание суммы за согласованный срок договора осуществляется автоматически.

            4.3. Срок для расторжения договора истекает за 14 дней до окончания срока действия договора. Таким образом,
            отменить продление можно в любое время, но не позже, чем за 15 дней до конца текущего договора.

            4.4. Отказ от платного аккаунта является действительным только в том случае, если он четко изъязвлен в
            письменной форме.

            4.5. При письменном расторжении договора для четкой аутентификации и для защиты от злоупотреблений
            необходимо, чтобы клиент указал в своем заявлении о расторжении договора свой адрес электронной почты и
            контактный номер. Письменное заявление о расторжении договора должно быть направлено по электронной почте на
            #EMAIL#.

            4.6. После окончания действия платного аккаунта происходит автоматическое продление подписки клиента (см.п.
            10.1).

            4.7. Право обеих сторон на внеочередное расторжение договора как на Базовый аккаунт, так и на платный
            аккаунт, остается нетронутым. #SERVICE_NAME# имеет, в частности, основание для внеочередного расторжения,
            если клиент при регистрации или при заказе и/или последующем изменении своих данных умышленно указал
            неверные сведения, мешающие работе #SERVICE_NAME# или, неоднократно нарушил правила #SERVICE_NAME#, в
            частности те, о которых говорится в пункте 6 данного документа, а также, если #SERVICE_NAME# безрезультатно
            предупредил клиента о необходимости соблюдения Общих условий заключения сделок (предупреждение).
        </p>
        <h2>5. Ответственность #SERVICE_NAME#</h2>

        <p>
            5.1. В случае неисполнения или ненадлежащего исполнения условий настоящего соглашения, стороны несут
            ответственность‚ предусмотренную настоящим соглашением и законодательством Российской Федерации.

            5.2. Пользователь соглашается, что Сервис‚ предоставленный ему Оператором, может являться объектом
            интеллектуальной собственности, права на который защищены.

            5.3. В отношении текстовых материалов (статей, публикаций, находящихся в свободном публичном доступе на
            Сайте) разрешается их распространение в любом виде и в любых источниках, только при условии, что будет дана
            активная ссылка на сайт в соц. сетях, мессенджере. Любой вид информации статей, публикаций, находящихся в
            ограниченном, платном доступе на Сайте, распространять в любом виде запрещено. При несоблюдении данного
            пункта последует немедленная блокировка пользователя без возврата средств за действующую подписку.

            5.4. Оператор не несет ответственности за любые прямые или косвенные убытки, произошедшие у пользователя в
            связи с использованием либо невозможностью использования сайта, либо его отдельных сервисов пользователем, а
            также, в случае несанкционированного доступа третьих лиц к личному кабинету.

            5.5. Оператор не несет ответственность за любого рода убытки пользователя в рамках использования услуг
            сервиса #SERVICE_NAME#. #SERVICE_NAME# лишь делится своей экспертной аналитической информацией из области
            фитнеса, как на бесплатных условиях, так и на платных условиях. Приобретая подписку, либо другие услуги
            #SERVICE_NAME# пользователь соглашается с тем фактом, что он идет на определенные финансовые риски.
            #SERVICE_NAME# не гарантирует 100% совпадения прогнозируемой бесплатной и платной информации с
            действительностью.

            5.6. Вышеназванные ограничения ответственности применяются соответствующим образом ко всем органам,
            работникам и привлеченным к исполнению третьим лицам #SERVICE_NAME#.

            5.7. Оператор не несет ответственности за несоответствие предоставленной Услуги ожиданиям пользователя и/или
            за его субъективную оценку, такое несоответствие ожиданиям и/или отрицательная субъективная оценка не
            являются основаниями считать услуги оказанными некачественно, или не в согласованном объеме.
        </p>
        <h2>6. Сфера ответственности и обязанности клиента / запрещенные действия / освобождение от ответственности</h2>

        <p>
            6.1. Клиент отвечает за достоверность всей предоставленной о себе информации в любом ресурсе #SERVICE_NAME#.

            6.2. Клиент гарантирует, что введенные данные соответствуют действительности и описывают его лично. Стороны
            договора согласны с тем, что #SERVICE_NAME# имеет право проверять введенные данные на их правильность.

            6.3. Клиент гарантирует, что он не преследует никаких профессиональных и (или) предпринимательских целей в
            связи с его регистрацией. Он обязуется не использовать информацию с ресурсов #SERVICE_NAME# в
            профессиональных или предпринимательских целях.

            6.4. Клиент гарантирует, что в рамках предложений и сервисов #SERVICE_NAME# он не загружает фотографии,
            тексты, компьютерные программы или иную информацию, защищенную авторским правом, без наличия необходимых для
            этого прав или согласий.

            6.5. Клиент обязуется соблюдать все применимое законодательство при регистрации.
        </p>

        <p>
            6.6. Клиент не имеет права ни при каких обстоятельствах (запрещенные действия): умышленно публично делиться в
            любых Интернет-ресурсах любыми видами данных из платных чатов и услуг компании #SERVICE_NAME#. В частности,
            без согласия правоуполномоченных третьих лиц клиент не имеет права указывать:
        </p>

        <ul>
            <li>1) банковские реквизиты или банкоские данные третьих лиц;</li>

            <li>
                2) открывать доступ к персональной информации, в частности к именам, номерам телефона и факса,
                почтовым
            </li>
            адресам, адресам электронной почты, файлам фотографий и видеозаписей и (или) URL-адреса;
        </ul>

        <p>Клиент также не имеет права ни при каких обстоятельствах:</p>

        <ul>
            <li>1) распространять порочащие, неприличные или иные противоправные материалы или подобную информацию;</li>

            <li>
                2) угрожать иным лицам, нарушать покой иных лиц или нарушать права (включая личные права) третьих
                лиц;
            </li>

            <li>3) загружать данные, содержащие вирусы (инфицированные компьютерные программы);</li>

            <li>
                4) использовать сервис таким образом, который неблагоприятно влияет на доступность предложений иным
                клиентам;
            </li>

            <li>5) пытаться мошенническим путем получить доступ к платным чатам и услугам компании #SERVICE_NAME#;</li>

            <li>6) распространять рекламу любого рода, в любом виде;</li>

            <li>7) отправлять цепочки писем;</li>

            <li>
                8) в личном описании (профиле) указывать имена, адреса, номера телефонов и факсов, адреса электронной
                почты,
                имена пользователя или иные контактные данные из систем обмена сообщениями или прочих интернет-сервисов;
            </li>

            <li>
                9) передавать третьим лицам или совместно с третьими лицами использовать имена пользователей и личные
                пароли.
            </li>
        </ul>

        <p>
            6.7. Недопустимым использованием в профессиональных или предпринимательских целях по смыслу настоящей нормы
            является: предложение любых товаров или услуг за плату, призыв к даче соответствующей оферты или ссылка на
            соответствующую оферту, доступную в другом месте; рекламирование коммерческих интернет-сайтов, т.е., в
            частности, таких сайтов, посредством которых предлагаются товары или услуги за плату, которые служат
            представлению или рекламе предприятий или рекламируют иные коммерческие интернет-сайты. Это касается, в
            частности, рекламы в форме всплывающих окон, баннерной рекламы или особенно выделяющихся или заметных
            ссылок; создание ссылок (прямое или непрямое) на контактный портал иного сервиса; указание номеров
            дополнительных специальных услуг в области коммуникации (в частности телефонных номеров, начинающихся с 800
            ) или номеров для смс с дополнительной стоимостью (премиум-смс) в рамках настоящего портала экспертной
            аналитики в области фитнеса #SERVICE_NAME#, поиск работников, и т.п. для подобных порталов или любого рода
            коммерческих организаций сферы услуг; коммерческий сбор данных профилей, доступных в рамках контактного
            портала, или запрос данных (например, номеров телефона) у других клиентов, например с целью коммерческого
            использования, рекламы или перепродажи; отправка другим клиентам сервиса или иным клиентам рекламы в любой
            форме, касающейся коммерческих предложений, и отправка сообщений, служащих коммерческим целям. Это касается,
            в частности, установления и указания соответствующих ссылок в профилях данных или отправки сообщений
            посредством внутренней системы коммуникации.

            6.8. Клиент обязуется не причинять #SERVICE_NAME# убытков какими-либо видами жалоб, убытков, потерь или
            требований, которые могут возникнуть в результате его регистрации и (или) участия в данном сервисе, если
            данные убытки не основываются на умысле или неосторожности #SERVICE_NAME# или его законных представителей
            или привлеченных им третьих лиц. В особенности, клиент обязуется освободить #SERVICE_NAME# от любой
            ответственности и от всех обязательств, затрат и требований, которые могут возникнуть в отношении
            #SERVICE_NAME# в результате неразрешенных действий клиента, например, вследствие клеветы, оскорбления,
            нарушения личных прав, нарушения интеллектуальной собственности или иных прав третьих лиц или отказа сервиса
            для других клиентов.
        </p>
        <h2>7. Условия оплаты</h2>

        <p>
            7.1. Оплата банковскими картами осуществляется через платежную систему.

            7.2. К оплате принимаются карты VISA, MasterCard, МИР.

            7.3. Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем
            Visa, MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности
            совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по
            закрытым каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице. На
            странице для ввода данных банковской карты потребуется ввести данные банковской карты: номер карты, имя
            владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код
            Дополнительной Идентификации для МИР). Все необходимые данные пропечатаны на самой карте. Трёхзначный код
            безопасности — это три цифры, находящиеся на обратной стороне карты. Далее вы будете перенаправлены на
            страницу Вашего банка для ввода кода безопасности, который придет к Вам в СМС. Если код безопасности к Вам
            не пришел, то следует обратиться в банк выдавший Вам карту.

            7.4. Случаи отказа в совершении платежа:

            7.4.1. Банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать,
            обратившись в Ваш Банк;

            7.4.2. Недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте
            Вы можете узнать, обратившись в банк, выпустивший банковскую карту;данные банковской карты введены неверно;

            7.4.3. Истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне
            карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать,
            обратившись в банк, выпустивший банковскую карту;

            7.5. По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете
            обращаться по следующим телефонам: +79161789631.

            7.6. Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты)
            является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в
            зашифрованном виде и не сохраняются на нашем Web-сервере.

            7.7. Внесение платы должно быть заранее произведено клиентом #SERVICE_NAME# за выбранный период без
            каких-либо удержаний. Платежи клиентов всегда сначала засчитываются в порядке уплаты процентов, пеней и
            прежней задолженности.

            7.8. Посредством приобретения платного аккаунта и указания платежной информации во время покупки клиент
            наделяет #SERVICE_NAME# правом осуществления платежной операции.

            7.9. Стоимость Услуг устанавливается оператором в тарифах, опубликованных на сайте или в любом другом
            официальном источнике.

            7.10. Оператор вправе в одностороннем порядке полностью или частично изменять (увеличивать, уменьшать),
            устанавливать новые, отменять существующие тарифы. В случае несогласия пользователя с изменением (введением
            в действие) новых тарифов пользователь вправе в одностороннем порядке отказаться от исполнения настоящего
            Соглашения в порядке, предусмотренном разделом 11.1. настоящего Соглашения.

            7.11. Оплата вознаграждения вносится Пользователем в размере 100% предоплаты (иной порядок оплаты возможен в
            соответствии с условиями проведения акций (специальное предложение) в момент подтверждения с использованием
            сервиса заявки).

            7.12. Оплата услуг оператора в общем случае осуществляется в виде безакцептного списания (списания средств
            со счета банковской карты, не требующего подтверждения пользователем) денежных средств со счета
            пользователя, в соответствии с действующими тарифами. Безакцептное списание происходит в сумме, равной
            стоимости тарифа, выбранного Клиентом. Безакцептное списание со счёта Клиента сумм, равных стоимости тарифа,
            происходит во всё время действия настоящего соглашения. Если на счете пользователя недостаточно средств для
            оплаты услуг оператора, оператор вправе повторять попытки списания денежных средств в качестве обеспечения
            своего коммерческого интереса, при этом если на счёте клиента имеется сумма меньшая чем стоимость тарифа, то
            #SERVICE_NAME# вправе произвести безакцептное списание сумм меньших чем стоимость тарифа до тех пор, пока не
            спишет сумму денежных средств равной стоимости тарифа. Сервис работает на условиях периодической платной
            подписки.

            7.13. При оказании услуг, акт об оказанных услугах не формируется и не подписывается. Услуги считаются
            оказанными надлежащим образом и принятыми пользователем в полном объеме, если в течение одного рабочего дня
            по истечении срока оказания услуг оператор не получил от пользователя мотивированных письменных возражений
            по качеству оказанных услуг на электронную почту #EMAIL# . Электронное письмо считается полученным и
            прочтенным на следующий рабочий день после его отправления. #SERVICE_NAME# не несет ответственности в случае
            получения убытков в результате следования рекомендациям Исполнителя. В случае оформления подписки на услуги,
            пользователь вправе отменить подписку согласно требованиям пункта 4.3. Отсутствие любых письменных замечаний
            в течение установленного срока считается признанием факта надлежащего качества услуг. Возврат средств может
            производиться только на ту карту, с которой производилась оплата услуг оператора. Подписка действительна на
            протяжении всего срока договора-оферты.
        </p>
        <h2>8. Блокирование подозрительных клиентов</h2>

        <p>
            8.1. #SERVICE_NAME# имеет право в случае обоснованной жалобы третьего лица, подозрения в нарушении права или
            нарушении настоящего Пользовательского соглашения заблокировать сведения клиента, которые повлекли за собой
            указанную жалобу или указанное подозрение. Право внеочередного расторжения договора не затрагивается
            вышестоящим положением.

            8.2. Если предварительное предупреждение является приемлемым для #SERVICE_NAME#, для блокировки необходимо
            предварительное предупреждение #SERVICE_NAME#, чтобы дать клиенту возможность рассеять подозрения или
            устранить недостатки. Если предварительное предупреждение не является приемлемым, например, если блокировка
            является необходимой для того, чтобы предотвратить возможные убытки #SERVICE_NAME# или другого клиента,
            #SERVICE_NAME# проинформирует клиента незамедлительно после осуществления блокировки и даст ему возможность
            высказать свою позицию и устранить недостатки.

            8.3. #SERVICE_NAME# может в любое время удалить или заблокировать сведения, если этого потребуют от
            #SERVICE_NAME# суд или органы государственной власти или если отношения пользования были прекращены. То же
            самое распространяется на случаи, в которых удаление или блокирование являются необходимыми, чтобы
            предотвратить грозящие убытки #SERVICE_NAME# или другого клиента.

            8.4. Если доступ клиента к услугам платного аккаунта был заблокирован и впоследствии выяснится, что
            блокировка была неоправданной, срок действия платных услуг продлевается соответствующим образом. Если
            продление невозможно или объективно неприемлемо, #SERVICE_NAME# возмещает вознаграждение, соответствующее
            периоду блокировки.
            9. Изменения Общих условий заключения сделок

            9.1. #SERVICE_NAME# сохраняет за собой право изменить настоящее Пользовательское соглашение с применением
            обратной силы. Изменения считаются принятыми, если клиент зашел на ресурс #SERVICE_NAME#, где будет указано
            об изменениях (сайт). Также изменения считаются принятыми автоматически каждым клиентом спустя трое суток с
            момента внесенных изменений. Если клиент возражает какому-либо изменению, #SERVICE_NAME# имеет право
            расторгнуть отношения пользования на основании общих правил данного соглашения.
        </p>
        <h2>10. Расторжение соглашения</h2>

        <p>
            10.1. Волеизъявление на заключение договора может быть отозвано в течение двух недель без указания причин в
            письменной форме на e-mail #EMAIL# . Срок начинает течь с момента получения настоящего разъяснения, но не до
            заключения договора и не до исполнения информационных обязанностей #SERVICE_NAME#. Своевременная отправка
            является достаточной для соблюдения срока на отзыв волеизъявления.

            10.2. Право на отзыв волеизъявления досрочно утрачивается, если договор по Вашему прямому желанию полностью
            исполнен с обеих сторон до того, как Вы воспользовались своим правом на отзыв.
            11. Политика возвратов денежных средств

            11.1. Услуги, оказываемые Исполнителем по настоящему Соглашению являются цифровыми, Оператор предлагает
            Пользователю возможность оформить подписку на услуги за минимальную плату. По общему правилу возврат платы
            за услуги не предусмотрен, тем не менее, Оператор оставляет за собой право рассматривать обращения,
            связанные с возвратом средств за услуги в индивидуальном порядке.

            11.2. При доказанных фактах мошеннических действий со стороны Пользователя, либо при нарушении Пользователем
            п. 5.3 настоящего Соглашения, возврат средств, оплаченных за услуги не производится.

            11.3. Полный возврат возможен в случаях:

            11.3.1. Когда доступ к услугам не был предоставлен Пользователю в течение 24 (двадцати четырех) часов с
            момента оплаты услуг по соответствующему Тарифу, либо был заблокирован для Пользователя, без нарушения им
            своих обязательств по настоящему Соглашению, на соответствующий временной отрезок в текущем, оплаченном
            периоде оказания услуг;

            11.3.2. Оплата была произведена ошибочно, то есть если услуги по одному и тому же Тарифу были оплачены
            Пользователем с интервалом не более 10 минут между платежами и если при этом Пользователь обратился к
            Оператору с заявлением о возврате денежных средств, в период не превышающий 48 (сорока восьми) часов с
            момента второго платежа.

            11.4. Частичный возврат возможен в случаях:

            11.4.1. Если услуги были недоступны для Заказчика по вине, либо по причине бездействия Оператора срок более
            3 (трех) часов подряд, при этом возврат производится в размере 5% от суммы оплаченной Пользователем за
            оказание услуг в текущем периоде.

            11.5. Возвраты производятся только при непосредственном обращении Пользователя к Оператору с соответствующим
            заявлением. При обращении за возвратом к поставщикам платежных услуг Оператор возврат средств не производит,
            во избежание двойного возмещения.
        </p>
        <h2>12. Изменение условий</h2>

        <p>
            12.1. Изменения и дополнения к настоящему соглашению, вступают в силу с момента их опубликования на
            официальном сайте. Пользователь понимает и согласен с тем, что, если он пользуется сайтом после даты
            внесения соответствующих изменений в настоящее соглашение, это будет расцениваться как факт принятия
            пользователем соглашения в новой редакции. Принятие пользователем изменений в обновленном соглашении
            вступает в силу в любом случае спустя сутки с момента публикации на официальном сайте.

            12.2. Пользователь вправе отказаться от принятия изменений и дополнений соглашения, производимых оператором,
            что означает отказ пользователя от использования сайта или сервиса.
        </p>
        <h2>13. Заключительные положения</h2>

        <p>
            13.1. Местом исполнения и исключительным местом рассмотрения споров является адрес/место нахождения ИП
            "#ФИО#", если иное не предусмотрено законодательством.

            13.2. Устные или письменные дополнительные соглашения отсутствуют.
        </p>

        <p>
            13.3. Если отдельные положения настоящего соглашения являются или станут недействительными, остальные
            положения договора остаются действительными. Договор-оферта автоматически пролонгируется на следующий
            срок.
        </p>
        <p>Реквизиты и контактная информация Исполнителя:</p>

        <ul>
            <li>ИП "#ФИО#", #АДРЕС#</li>

            <li>Индекс #ИНДЕКС#</li>

            <li>ИНН – #ИНН#</li>

            <li>Служба поддержки: #EMAIL#</li>

            <li>ОГРНИП: #ОГРНИП#</li>

            <li>ИНН: #ИНН#</li>
        </ul>
        <h2>Условия Конфиденциальности #SERVICE_NAME#</h2>
        <h2>1. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>

        <p>
            1.1. Если в рамках настоящей Оферты Исполнитель получит от Заказчика Персональные данные физических лиц, то
            Исполнитель обязуется обрабатывать Персональные данные строго в соответствии с принципами и правилами
            обработки персональных данных, предусмотренными законодательством РФ. Заказчик передает Исполнителю
            персональные данные с целью оказания Услуг, указанных в настоящей оферте и поручает Исполнителю их
            обработку, совершаемую с использованием средств автоматизации или без использования таких средств, включая
            сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
            персональных данных.

            1.2. Исполнитель обеспечивает соблюдение всех требований законодательства и иных нормативно-правовых актов
            РФ в отношении обработки и передачи персональных данных физических лиц, полученных от Заказчика.

            1.3. Исполнитель использует персональные данные физических лиц, полученные от Заказчика только для оказания
            Услуг в рамках настоящей Оферты.

            1.4. Заказчик обязан выполнить все требования законодательства и иных нормативно-правовых актов в отношении
            обработки и передачи Исполнителю персональных данных физических лиц, которые передаются Исполнителю. Все
            персональные данные, полученные Исполнителем от Заказчика, подразумеваются полученными наш законном
            основании с соблюдением положений законодательства о персональных данных* Заказчик признает, что нарушение
            им порядка обращения персональных данных не налагает на Исполнителя какой-либо ответственности и лишает
            права Заказчика предъявлять претензии к Исполнителю в отношении нарушения режима персональных данных, если
            только такое нарушение не было виновно совершенно Исполнителем при условии соблюдения Заказчиком всех
            требований действующего законодательства об обращении персональных данных.
        </p>

        <h2>СОГЛАСИЕ на получение рассылки и рекламных материалов</h2>

        <p>
            Я, регистрируясь и/или вводя свои данные на сайте #URL#, его сервисах или его поддоменах, даю своё согласие
            на получение рассылки материалов рекламного и/или информационного характера посредством SMS-сервисов,
            электронной почты, мессенджеров, с помощью телекоммуникационных каналов связи от ИП "#ФИО#"

            Я регистрируюсь и/или ввожу свои данные в #URL# , а также на его сервисах или его поддоменах (в том числе с
            целью направления заявки на получении услуг). Даю свое согласие на получение рассылки, в том числе
            рекламной, содержащей информацию, в том числе, но не ограничиваясь, о товарах и услугах, наличие специальных
            предложений, акций в отношении них, условиях, связанных с приобретением и использованием указанных товаров и
            услуг, о проведении мероприятий, презентаций, предложение партнеров, а также рассылок подготовленных в
            качестве личных рекомендаций для меня с учетом анализа покупательского поведения посредством рассылки
            (направление сообщений на электронную почту или с помощью средств мест массовой информации).

            Давая такое согласие, я подтверждаю, что действую по своей воле и в своем интересе, а также обязуюсь
            предоставить достоверные данные.

            Я подтверждаю, что владею информацией о том, что в любой момент в течение всего срока действия настоящего
            согласия, я вправе отозвать согласие данного соглашения, и отписаться от получения рассылок путем перехода
            по соответствующей ссылке, существующей в любом письме.

            Также я информирован (-а), что при возникновении вопросов относительно отказа, в том числе от смс рассылок,
            я могу обратиться за помощью, отправив письмо на следующий адрес электронной почты: #EMAIL#
        </p>

        <p>Реквизиты и контактная информация Исполнителя:</p>

        <ul>
            <li>ИП "#ФИО#", #АДРЕС#</li>

            <li>Индекс #ИНДЕКС#</li>

            <li>ИНН – #ИНН#</li>

            <li>Служба поддержки: #EMAIL#</li>
        </ul>

        <h2>Тарифы</h2>

        <p>
            #SITE# предлагает #SERVICES#.

            Стоимость услуги составляет #COST#.

            При продлении договора, списание суммы за согласованный срок договора осуществляется автоматически.
        </p>

        <p>утверждено: 02.04.2021 г.</p>
    </div>
</template>
