<template>
    <div
        class="question-select"
    >
        <a-select
            v-model:value="selected"
            :placeholder="placeholder"
            class="question-select__select"
            @change="handleChange"
        >
            <a-select-option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
            >
                {{ option.text }}
            </a-select-option>
        </a-select>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
let unsubscribe;

export default {
    name: 'QuestionSelect',
    props: {
        placeholder: {
            type: String,
            default: 'Выберите',
        },
        options: Array,
    },
    data() {
        return {
            selected: undefined,
        };
    },
    mounted() {
        this.getDefaultData();
        unsubscribe = this.$store.subscribe(mutation => {
            if (mutation.type === 'nextStep' || mutation.type === 'prevStep') {
                this.getDefaultData();
                console.log('QuestionSelect', this.selected);
            }
        });
    },
    unmounted() {
        unsubscribe();
    },
    methods: {
        ...mapActions([
            'handleChange',
        ]),
        getDefaultData() {
            const { stepValues, stepFields } = this.$store.getters;

            const value = stepValues[stepFields[0]];

            if (value) {
                this.selected = value;
            }
        },
    },
};
</script>

<style lang="sass">
.question-select__select
    width: 200px
</style>
