<template>
    <div class="result container">
        <a-typography v-if="user">
            <a-typography-title>На основе полученных данных для пользователя "{{ user.name }}"</a-typography-title>
            <a-row class="result__bodytype-row">
                <a-col
                    :sm="14"
                    :xs="24"
                >
                    <a-typography-paragraph>
                        Твой тип телосложения: <strong>{{ getValue(user.somatotype) }}
                            ({{ getValue(userSoma.body) }})</strong>
                    </a-typography-paragraph>
                    <ul>
                        <li><strong>Развитые системы:</strong> {{ userSoma.systems }}</li>
                    </ul>
                    <a-typography-paragraph>
                        Твой тип темперамента: <strong>{{ userSoma.characterName }}</strong>
                    </a-typography-paragraph>
                    <ul>
                        <li><strong>Присущие тебе черты характера:</strong> {{ userSoma.character }}</li>
                    </ul>
                    <a-typography-paragraph>
                        Твой соматотип: <strong>{{ getValue(user.somatotype) }}</strong>
                    </a-typography-paragraph>
                    <ul>
                        <li>
                            <strong>Это значит, что тебе следует отнестись со вниманием к следующим заболеваниям:</strong>
                            {{ userSoma.diseases.join(', ') }}
                        </li>
                    </ul>

                    <a-typography-paragraph>
                        <strong>Основная рекомендация по питанию:</strong> {{ userSoma.food }}
                    </a-typography-paragraph>
                </a-col>
                <a-col
                    :sm="10"
                    :xs="24"
                    class="result__bodytype-image-wrapper"
                >
                    <a-image
                        class="result__bodytype-image"
                        :src="'/images/bodytype-'+user.somatotype+'.jpg'"
                    />
                </a-col>
            </a-row>

            <a-typography-title :level="2">
                Персональный расчет по калоражу питания
            </a-typography-title>

            <a-row
                class="stat-cards-wrapper"
                :gutter="20"
            >
                <a-col
                    :lg="8"
                    :sm="12"
                    :xs="24"
                >
                    <stat-card>
                        <template #num>
                            {{ user.body_mass_index }}
                        </template>
                        <template #description>
                            Твой Индекс Массы Тела (ИМТ)
                        </template>
                    </stat-card>
                </a-col>
                <a-col
                    :lg="8"
                    :sm="12"
                    :xs="24"
                >
                    <stat-card :color="user.fat_index_status">
                        <template #num>
                            {{ user.fat_index }}
                        </template>
                        <template #description>
                            Твой Индекс Массы Жира (ИМЖ)
                        </template>
                    </stat-card>
                </a-col>
                <a-col
                    :lg="8"
                    :sm="24"
                    :xs="24"
                >
                    <stat-card>
                        <template #num>
                            {{ user.fat_percent }}%
                        </template>
                        <template #description>
                            <a-typography-paragraph>
                                Процент содержания жира.<br>
                                А его вес: <strong class="nobr">{{ user.fat_weight }} кг</strong>
                            </a-typography-paragraph>
                        </template>
                    </stat-card>
                </a-col>
            </a-row>

            <a-typography-paragraph>
                Твоя величина основного обмена то есть калории,
                которые твой организм затрачивает просто на то, чтобы дышать,
                переваривать пищу, заниматься процессами кроветворения и,
                например, роста волос и ногтей: <strong class="nobr">{{ user.base_methabolism }} ккал/сут</strong>.
            </a-typography-paragraph>

            <a-typography-paragraph>
                Величина основного обмена веществ с учетом активности: <strong>{{
                    user.main_methabolism
                }} ккал/сут</strong>.
            </a-typography-paragraph>
            <a-typography-paragraph>
                Замеры, которые мы снимали калипером или линейкой,
                позволяют вычислить величину твоего основного обмена совсем точно -
                без учета жировой массы: <strong class="nobr">{{ user.thick_metabolism }} ккал/сут</strong>.
            </a-typography-paragraph>

            <a-card
                class="result__warning"
                :bordered="false"
                type="inner"
            >
                <a-typography-title
                    :level="4"
                    type="warning"
                >
                    Обрати внимание!
                </a-typography-title>
                <a-typography-paragraph>
                    Это - минимальный показатель калорий, ниже которого тебе не стоит опускаться ни в
                    коем случае.
                </a-typography-paragraph>
            </a-card>

            <a-typography-paragraph>
                В соответствии с {{ user.goals.length > 1 ? 'твоими целями' : 'твоей целью' }}:
                <strong>{{ getValue(user.goals) }}</strong>
            </a-typography-paragraph>
            <a-typography-paragraph>
                В день тренировки/активных физических нагрузок (тренировки, танцы, длительные прогулки):
                нужно употреблять <strong class="nobr">{{ user.daily_energy_training }} ккал/сут</strong>.
            </a-typography-paragraph>
            <a-typography-paragraph>
                В день отдыха и детокса: <strong class="nobr">{{ user.daily_energy_fat_burn }}
                    ккал/сут</strong>.
            </a-typography-paragraph>

            <a-typography-paragraph>
                Также мы подготовили для тебя распределение калорий по нутриентам (Белкам, Жирам и
                Углеводам).
            </a-typography-paragraph>

            <a-typography-paragraph>
                Ты сможешь отслеживать этот показатель в приложении <a
                    target="_blank"
                    href="https://www.fatsecret.ru/"
                ><strong>FatSecret</strong></a>,
                ориентируясь на цифры, указанные в этом расчете,
                а не на ту информацию, которую выдаст тебе приложение
                (не учитывающее жировую массу при составлении расчетов).
            </a-typography-paragraph>

            <nutrients-chart :program="userProgramRegular" />
            <!--TODO: понять что тут-->

            <template v-if="userProgramSlim">
                <nutrients-chart :program="userProgramSlim">
                    <template #title>
                        Снижение веса
                    </template>
                </nutrients-chart>
            </template>

            <template v-if="userProgramMuscle">
                <nutrients-chart :program="userProgramMuscle">
                    <template #title>
                        Набор мышечной массы
                    </template>
                </nutrients-chart>
            </template>

            <a-typography-paragraph>
                <strong>Не забывай пить достаточное количество чистой воды: </strong>
                <span class="nobr">{{ user.water_per_day }} л/день</span>.
            </a-typography-paragraph>

            <!--TODO: рекомендации из файлов-->

            <div class="text-center">
                <a-typography-paragraph>Желаем удачи в достижении твоих целей!</a-typography-paragraph>
                <a-typography-paragraph class="text-grey">
                    Остались вопросы - напиши нам:
                    <a href="mailto:support@timetoeat.ru">support@timetoeat.ru</a>
                </a-typography-paragraph>
            </div>
        </a-typography>
        <template v-if="!user && error">
            <a-card title="Ошибка">
                <a-typography-paragraph class="error-text">
                    {{ error }}
                </a-typography-paragraph>
            </a-card>
        </template>
        <div
            v-if="!user && !error"
            class="result__loading"
        >
            <a-spin
                size="large"
                tip="Загрузка..."
            />
        </div>
    </div>
</template>

<script>
import { fetcher, getDictValue, nutrientsCount } from '@/helpers';
import { somatotypeData } from '@/constants';
import isEqual from 'lodash/isEqual';

import NutrientsChart from '@/components/NutrientsChart';
import StatCard from '@/components/StatCard';

const USER_CACHE = 'user_cache';

export default {
    name: 'Result',
    components: { NutrientsChart, StatCard },
    data() {
        return {
            user: null,
            userSoma: {},
            userProgramRegular: {},
            userProgramSlim: null,
            userProgramMuscle: null,
            error: null,
        };
    },
    async created() {
        const userId = this.$route.params.userId;

        try {
            const cahedUserDataString = localStorage.getItem(USER_CACHE);

            if (cahedUserDataString) {
                const cachedUserData = JSON.parse(cahedUserDataString);

                this.setPageData(cachedUserData);
            }
        } catch (e) {
            console.log('can not set user from cache');
        }

        try {
            const user = await fetcher.get(`/api/users/result/${userId}`);

            if (user && !isEqual(user, { ...this.user })) {
                this.setPageData(user);
                localStorage.setItem(USER_CACHE, JSON.stringify(user));
            }

            window._user = { ...this.user };
        } catch (e) {
            this.error = 'Не удалось получить данные пользователя';
        }
    },
    methods: {
        getValue: getDictValue,
        getProgramDonutData(program) {
            const labels = [ 'Белки', 'Жиры', 'Углеводы' ];

            return program.map((value, idx) => ({ value, label: labels[idx] + ` - ${program[idx]}%` }));
        },
        setPageData(user) {
            if (user) {
                this.user = user;
                this.userSoma = somatotypeData[user?.somatotype] || {};
                this.userProgramRegular = nutrientsCount(user.daily_energy_consumption, user.sex);
                this.userProgramSlim = nutrientsCount(user.daily_energy_fat_burn, user.sex, 'slim');
                this.userProgramMuscle = nutrientsCount(user.daily_energy_training, user.sex, 'muscle');
            }
        },
    },
};
</script>

<style lang="sass">
@import '../variables'

.result
    background: white
    padding-top: 50px
    padding-bottom: 50px
    margin-left: -50px
    margin-right: -50px

.result__donut-chart
    justify-content: flex-start
    margin-bottom: 20px

.error-text
    color: $color-red

.result__loading
    text-align: center
    padding: 50px

.result__warning
    font-size: 16px
    margin-bottom: 20px
    +main-box-shadow('mini')

.result__bodytype-image-wrapper
    position: relative

.result__bodytype-image
    overflow: hidden
    position: relative
    width: 100%
    max-height: 350px
    background: top center/contain no-repeat
    border-radius: 20px
    +main-box-shadow('mini')

.text_status
    @each $status, $color in ('low': $color-warning, 'supranormal': $color-warning, 'high': $color-danger)
        &.#{$status}
            color: $color

.stat-cards-wrapper
    .stat-card
        height: 100%
    .ant-col
        margin-bottom: 20px

@media (max-width: 575px)
    .result
        margin-left: -20px
        margin-right: -20px

    .result__bodytype-row
        flex-direction: column-reverse

    .result__bodytype-image-wrapper
        flex-basis: auto
        margin-bottom: 20px
        align-self: center
        width: 100%
        text-align: center
    .result__bodytype-image
        max-height: 250px

</style>
