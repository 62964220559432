export const quizTypes = {
    info: 'info',
    icon: 'icon',
    input: 'input',
    select: 'select',
    selectButtons: 'selectButtons',
    card: 'card',
    number: 'number',
    result: 'result',
    date: 'date',
};

export const validationRules = {
    name: {
        presence: { allowEmpty: false },
        length: {
            minimum: 2,
            maximum: 80,
            tooShort: 'Имя некорректное',
            tooLong: 'Имя Leeloo Mina Lekatariba Laminatcha Ekba D Sebat можно покороче',
            notValid: 'Некорректное имя',
        },
    },
    phone: {
        format: {
            pattern: '(\\+7|8)[\\s(-]?\\d{3}[\\s)-]?\\d{3}[\\s-]?\\d{2}[\\s-]?\\d{2}',
            message: 'Некорректный телефон',
        },
    },
    email: {
        email: {
            message: 'Невалидный адрес почты',
        },

    },
    age: {
        numericality: {
            onlyInteger: true,
            greaterThan: 16,
            lessThan: 100,
            message: 'Неправильный возраст',
        },
    },
    birthdate: {
        presence: { allowEmpty: false },
    },
    sex: {
        presence: { allowEmpty: false },
    },
    height: {
        numericality: {
            greaterThan: 120,
            lessThan: 250,
            notLessThan: 'Рост: очень маленькое значение',
            notGreaterThan: 'Рост: очень большое значение',
        },
    },
    weight: {
        numericality: {
            greaterThan: 30,
            lessThan: 300,
            notLessThan: 'Вес: очень маленькое значение',
            notGreaterThan: 'Вес: очень большое значение',
        },
    },
    pectoral_fat: {
        numericality: {
            greaterThan: 0,
            lessThan: 500,
            notLessThan: 'Складка на груди: очень маленькое значение',
            notGreaterThan: 'Складка на груди: очень большое значение',
        },
    },
    abdominal_fat: {
        numericality: {
            greaterThan: 2,
            lessThan: 1000,
            notLessThan: 'Складка на животе: очень маленькое значение',
            notGreaterThan: 'Складка на животе: очень большое значение',
        },
    },
    thigh_fat: {
        numericality: {
            greaterThan: 2,
            lessThan: 1000,
            notLessThan: 'Складка на бедре: очень маленькое значение',
            notGreaterThan: 'Складка на бедре: очень большое значение',
        },
    },
    triceps_fat: {
        numericality: {
            greaterThan: 0,
            lessThan: 300,
            notLessThan: 'Складка трицепса: очень маленькое значение',
            notGreaterThan: 'Складка трицепса: очень большое значение',
        },
    },
    suprailiac_fat: {
        numericality: {
            greaterThan: 2,
            lessThan: 1000,
            notLessThan: 'Подвздошная складка: очень маленькое значение',
            notGreaterThan: 'Подвздошная складка: очень большое значение',
        },
    },
    waist_circum: {
        numericality: {
            greaterThan: 40,
            lessThan: 2000,
            notLessThan: 'Обхват талии: очень маленькое значение',
            notGreaterThan: 'Обхват талии: очень большое значение',
        },
    },
    thighs_circum: {
        numericality: {
            greaterThan: 50,
            lessThan: 2000,
            notLessThan: 'Обхват бедер: очень маленькое значение',
            notGreaterThan: 'Обхват бедер: очень большое значение',
        },
    },
    chest_circum: {
        numericality: {
            greaterThan: 50,
            lessThan: 2000,
            notLessThan: 'Обхват груди: очень маленькое значение',
            notGreaterThan: 'Обхват груди: очень большое значение',
        },
    },
    wrist_circum: {
        numericality: {
            greaterThan: 5,
            lessThan: 60,
            notLessThan: 'Обхват запястья: очень маленькое значение',
            notGreaterThan: 'Обхват запястья: очень большое значение',
        },
    },
    upper_pressure: {
        numericality: {
            greaterThan: 50,
            lessThan: 300,
            notLessThan: 'Верхнее давление: очень маленькое значение',
            notGreaterThan: 'Верхнее давление: очень большое значение',
        },
    },
    lower_pressure: {
        numericality: {
            greaterThan: 50,
            lessThan: 200,
            notLessThan: 'Нижнее давление: очень маленькое значение',
            notGreaterThan: 'Нижнее давление: очень большое значение',
        },
    },
    pulse: {
        numericality: {
            greaterThan: 30,
            lessThan: 300,
            notLessThan: 'Пульс: очень маленькое значение',
            notGreaterThan: 'Пульс: очень большое значение',
        },
    },
    password: {

        format: {
            pattern: '[a-zA-Z0-9\$\%\&-_=\+@#]{8,40}',
            message: 'Пароль должен содержать более 8 символов и состоять из латинских букв, цифр и символов: $ % & - _ = + @ #',
        },
    },
};

export const cacheStepKey = 'quizStepIndex';
export const cacheDataKey = 'quizData';

export const fieldsDict = {
    name: 'Имя',
    phone: 'Телефон',
    email: 'Почта',
    sex: 'Пол',
    male: 'Мужчина',
    female: 'Женщина',
    age: 'Возраст',
    birthdate: 'Дата рождения',
    height: 'Рост',
    weight: 'Вес',
    kfa: 'Тип физической активности',
    1.2: 'минимальная',
    1.375: 'низкая',
    1.55: 'средняя',
    1.73: 'высокая',
    1.9: 'очень высокая',
    habits: 'Привычки',
    late: 'Поздно ложусь',
    sugar: 'Ем сладкое / пью сладкие напитки',
    salt: 'Ем много соленого',
    smoking: 'Курю',
    alcohol: 'Употребляю алкоголь',
    none: 'Ничего',
    goals: 'Цели',
    slim: 'Снижение веса',
    bench: 'Увеличение работоспособности',
    muscle: 'Набор мышечной массы',
    immunity: 'Укрепление иммунитета',
    pectoral_fat: 'Толщина грудной складки',
    abdominal_fat: 'Толщина брющной складки',
    thigh_fat: 'Толщина складки бедра',
    triceps_fat: 'Толщина складки трицепса',
    suprailiac_fat: 'Толщина подвздошной складки',
    waist_circum: 'Обхват талии',
    thighs_circum: 'Обхват бедер',
    chest_circum: 'Обхват груди',
    wrist_circum: 'Обхват запястья',
    upper_pressure: 'Верхнее давление',
    lower_pressure: 'Нижнее давление',
    pulse: 'Пульс',
    fastfood: 'Вредная еда',
    body_mass_index: 'Индекс массы тела',
    daily_energy_consumption: 'Суточные энергозатраты',
    daily_energy_fat_burn: 'Суточные энергозатраты (жиросжигание)',
    daily_energy_training: 'Суточные энергозатраты (тренировка)',
    fat_index: 'Индекс массы жира (ИМЖ)',
    fat_index_status: 'Оценка индекса массы жира',
    fat_percent: 'Процент содержания жира',
    fat_sum: 'Сумма трех складок, мм',
    fat_weight: 'Масса жира, кг',
    base_methabolism: 'Величина базового обмена (Маффлен - Сен-Жеор)',
    main_methabolism: 'Величина основного обмена с учетом активности',
    methabolism_normal_diff: 'Процент отклонения основного обмена от нормы (Рид)',
    thick_metabolism: 'Величина обмена без учета жировой массы (Кетч-Макадамс)',
    thick_weight: 'Масса тела за вычетом жира (LBM)',
    waist_to_tighs: 'Отношение обхвата талия / бедра',
    water_per_day: 'Дневное количество воды',
    pulse_pressure: 'Пульсовое давление',
    roles: 'Роли',
    somatotype: 'Соматотип',
    sthenic: 'Нормостеник',
    asthenic: 'Астеник',
    hypersthenic: 'Гиперстеник',
    normal: 'Норма',
    low: 'Ниже нормы',
    supranormal: 'Выше нормы',
    high: 'Высокая',
    basics: 'Основное',
    caliperometry: 'Калиперометрия',
    circumference: 'Обхваты',
    heartMeathures: 'Показатели сердца',
    energyCounts: 'Расчет энергии',
    other: 'Другое',
    bodytype: 'Тип телосложения',
    ektomorph: 'Эктоморф',
    mesomorph: 'Мезоморф',
    endomorph: 'Эндоморф',
};

export const measureUnits = {
    // age: [],
    height: 'см',
    weight: 'кг',
    pectoral_fat: 'мм',
    abdominal_fat: 'мм',
    thigh_fat: 'мм',
    triceps_fat: 'мм',
    suprailiac_fat: 'мм',
    waist_circum: 'мм',
    thighs_circum: 'мм',
    chest_circum: 'мм',
    wrist_circum: 'мм',
    upper_pressure: 'мм.рт.ст',
    lower_pressure: 'мм.рт.ст',
    pulse: 'уд/м',
    main_methabolism: 'ккал/сут',
    thick_metabolism: 'ккал/сут',
    daily_energy_consumption: 'ккал/сут',
    daily_energy_fat_burn: 'ккал/сут',
    daily_energy_training: 'ккал/сут',
    fat_percent: '%',
    fat_sum: 'мм',
    fat_weight: 'кг',
    water_per_day: 'л',
    thick_weight: 'кг',
    pulse_pressure: 'мм.рт.ст',
};

export const somatotypeData = {
    asthenic: {
        diseases: [
            'Язвенная болезнь',
            'бронхо-легочные заболевания',
            'гипотоники', 'невротики',
        ],
        behavior: 'Необщителен, замкнут, скрытный, сколнен к внутренним переживаниям',
        body: 'Эктоморф',
        systems: 'Невная система и наружные покровы',
        characterName: 'Цребротоник',
        character: 'тяга к одиночеству, в тяжелые периоды жизни',
        food: 'Высокая калорийность, частые приемы, большие порции',
    },
    sthenic: {
        diseases: [ 'Дыхательные пути', 'опорно-двигательный аппарат', 'невралгии' ],
        behavior: 'Неторопливый, спокойный, не очень склонен к общению, но и не избегает его',
        body: 'Мезоморф',
        systems: 'Скелетные мышцы и скелет',
        characterName: 'Соматотоник',
        character: 'уверенность, экстраверсия, возможна агрессия при опьянении',
        food: 'Напрямую связано с образом жизни',
    },
    hypersthenic: {
        diseases: [ 'Ожирение',
            'диабет', 'заболевания ССС' ],
        behavior: 'Общительный, открытый, жизнерадостный,компанеец, скачки настроения',
        body: 'Мезоморф',
        systems: 'Внутренние органы',
        characterName: 'Висцеротоник',
        character: 'экстраверсивные, тяга к смене настроения',
        food: 'Низкокалорийное, частые приемы пищи, маленькие порции',
    },
};

export const userFieldsOrder = {
    basics: [
        'name',
        'email',
        'phone',
        'sex',
        'birthdate',
        'age',
        'height',
        'weight',
        'kfa',
        'habits',
        'goals',
    ],
    caliperometry: [
        'pectoral_fat',
        'abdominal_fat',
        'thigh_fat',
        'triceps_fat',
        'fat_sum',
        'fat_percent',
        'fat_weight',
        'fat_index',
        'fat_index_status',
        'thick_weight',
        'body_mass_index',
    ],
    circumference: [
        'waist_circum',
        'thighs_circum',
        'wrist_circum',
        'chest_circum',
        'waist_to_tighs',
    ],
    heartMeathures: [
        'upper_pressure',
        'lower_pressure',
        'pulse',
        'pulse_pressure',
    ],
    energyCounts: [
        'base_methabolism',
        'main_methabolism',
        'thick_metabolism',
        'methabolism_normal_diff',
        'daily_energy_consumption',
        'daily_energy_training',
        'daily_energy_fat_burn',
    ],
    other: [
        'water_per_day',
        'somatotype',
    ],
};
