<template>
    <a-layout>
        <a-layout-header>
            <a
                href="/"
                class="logo-block"
            >
                <logo />
                TimeToEat
            </a>
        </a-layout-header>
        <a-layout-content>
            <div class="container">
                <router-view />
            </div>
        </a-layout-content>
        <a-layout-footer>&copy;TimeToEat</a-layout-footer>
    </a-layout>
</template>

<script>
import Logo from '@/components/Logo';

export default {
    name: 'App',
    components: { Logo },
};
</script>

<style lang="sass">
.ant-layout
    min-height: 100vh

.ant-layout-header
    color: white

.logo-block
    display: flex
    align-items: center
    text-decoration: none
    color: white

    .logo
        margin-right: 10px
</style>
