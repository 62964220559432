<template>
    <div
        class="question-input"
    >
        <div class="question__options-list">
            <div
                v-for="option in options"
                :key="option.field || option.label || option.text"
                class="question__option"
            >
                <h4
                    v-if="option.label"
                    class="question__label"
                    :class="{required: option.required}"
                >
                    {{ option.label }}
                </h4>
                <a-input
                    v-if="option.type !== 'date'"
                    v-model:value="info[option.field || 'fieldMain']"
                    :type="option.type"
                    class="question__input"
                    :class="{required: option.required}"
                    :placeholder="option.text"
                    @change="onChange"
                />
                <a-date-picker
                    v-if="option.type === 'date'"
                    v-model:value="info[option.field || 'fieldMain']"
                    :placeholder="option.text"
                    format="DD.MM.YYYY"
                    class="question__datepicker"
                    @change="onChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
let unsubscribe;

export default {
    name: 'Question',
    props: {
        options: Array,
    },
    data() {
        return {
            info: {},
        };
    },
    mounted() {
        this.getDefaultData();
        unsubscribe = this.$store.subscribe(mutation => {
            if (mutation.type === 'nextStep' || mutation.type === 'prevStep') {
                this.getDefaultData();
            }
        });
    },
    unmounted() {
        unsubscribe();
    },
    methods: {
        getDefaultData() {
            const { stepValues, stepFields } = this.$store.getters;

            if (stepFields.length === 1) {
                this.info.fieldMain = stepValues[stepFields[0]];
            } else if (stepFields.length > 1) {
                this.info = { ...stepValues };
            }
        },
        onChange() {
            this.$store.dispatch('handleChange', this.info.fieldMain || { ...this.info });
            console.log('huy', JSON.stringify({ date: this.info?.birthdate }));
        },
    },

};
</script>

<style lang="sass">
@import '../variables'

.question__label.required
    &::after
        content: ' *'
        color: $color-red

.question__options-list
    display: flex
    flex-wrap: wrap

.question__option
    width: 100%
    margin-bottom: 20px

.question__input, .question__datepicker
    max-width: 300px
    width: 100%

@media (max-width: 500px)
    .question__input, .question__datepicker
        max-width: unset

</style>
