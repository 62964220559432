<template>
    <div
        class="logo"
        :class="{ black: !color }"
        :style="{ width: iWidth, height: iHeight }"
    />
</template>

<script>
import isNumber from 'lodash/isNumber';
export default {
    name: 'Logo',
    functional: true,
    props: {
        width: {
            type: [ Number, String ],
            default: null,
        },
        height: {
            type: [ Number, String ],
            default: null,
        },
        color: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        iWidth() {
            if (isNumber(this.width)) {
                return this.width + 'px';
            }
            return this.width;
        },
        iHeight() {
            if (isNumber(this.height)) {
                return this.height + 'px';
            }
            return this.height;
        },
    },
};
</script>

<style lang="sass">
    .logo
        background: url("../assets/time-to-eat-color.svg") center/contain no-repeat
        min-width: 20px
        min-height: 20px
        display: inline-block

        &.black
            background-image: url("../assets/time-to-eat-black.svg")
</style>
