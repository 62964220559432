<template>
    <div class="quiz">
        <a-page-header
            v-if="step.title"
            :title="step.title"
            @back="goBack"
        >
            <template
                v-if="stepIndex > 0"
                #backIcon
            >
                <ArrowLeftOutlined />
            </template>
            <a-progress :percent="progress" />
        </a-page-header>
        <div class="step-wrapper">
            <a-card
                v-if="step.description"
                class="question-description"
            >
                <a-image
                    v-if="step.image"
                    width="100%"
                    :src="step.image"
                    class="description-image"
                />
                <div v-html="macrosCondition(step.description, $store.state.userData)" />
            </a-card>

            <div class="question-wrapper">
                <template v-if="step.type === types.input">
                    <question-input
                        :options="stepOptions"
                    />
                </template>

                <template v-if="step.type === types.select">
                    <question-select
                        :options="stepOptions"
                    />
                </template>

                <template v-if="step.type === types.selectButtons">
                    <select-buttons
                        :multiple="step.multiple"
                        :options="stepOptions"
                    />
                </template>
                <template v-if="step.type === types.result">
                    <a-table
                        :columns="columns"
                        :data-source="tableData"
                        :scroll="{y: 300}"
                        size="small"
                        :pagination="false"
                    >
                        <template #action="{text:field}">
                            <SelectOutlined @click="goToStep(field)" />
                        </template>
                        <template #field="{text:field}">
                            {{ getValue(field) }}
                        </template>
                        <template #value="item">
                            {{ getValue(item.text) }} {{ measureUnits[item?.record?.field] }}
                        </template>
                    </a-table>
                </template>
            </div>
        </div>

        <a-button-group>
            <a-button
                v-if="$store.state.showForward"
                class="quiz__forward-button"
                @click="goForward"
            >
                Вперед
            </a-button>
            <a-button
                v-if="step.type === types.result"
                type="primary"
                @click="handleSubmit"
            >
                Отправить
            </a-button>
            <a-button
                v-if="step.goto"
                type="link"
                @click="goToStep(step.goto)"
            >
                {{ step.gotoLabel }}
            </a-button>
        </a-button-group>
    </div>
</template>

<script>
import QuestionInput from '@/components/QuestionInput';
import QuestionSelect from '@/components/QuestionSelect';
import SelectButtons from '@/components/SelectButtons';
import { mapActions, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { h } from 'vue';
import { notification } from 'ant-design-vue';
import { macrosCondition, fetcher, getDictValue } from '@/helpers';
import { SelectOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue';
import { cacheDataKey, cacheStepKey, measureUnits } from '@/constants';

export default {
    name: 'Quiz',
    components: { QuestionInput, QuestionSelect, SelectButtons, SelectOutlined, ArrowLeftOutlined },
    computed: {
        ...mapGetters([
            'step',
            'stepOptions',
            'steps',
            'types',
            'stepIndex',
            'progress',
        ]),
        columns() {
            return [
                {
                    key: 'field',
                    dataIndex: 'field',
                    title: 'Параметр',
                    slots: {
                        customRender: 'field',
                    },
                },
                {
                    key: 'value',
                    dataIndex: 'value',
                    title: 'Значение',
                    slots: {
                        customRender: 'value',
                    },
                },
                {
                    key: 'action',
                    dataIndex: 'field',
                    slots: {
                        customRender: 'action',
                    },
                    width: 40,
                },
            ];
        },
        tableData() {
            return map(this.$store.state.userData, (value, field) => ({ value, key: field, field }));
        },
        measureUnits: () => measureUnits,
    },
    methods: {
        ...mapActions([
            'goForward',
            'goBack',
            'goToStep',
        ]),
        macrosCondition,
        getValue: getDictValue,
        async handleSubmit() {
            const user = { ...this.$store.state.userData };

            user.phone = user.phone.replace(/\D+/g, '');
            const result = await fetcher.post('/api/users/create', user);

            console.log('result', { ...result });
            if (result.user) {
                notification.success({ message: 'Данные сохранены' });
                localStorage.removeItem(cacheDataKey);
                localStorage.removeItem(cacheStepKey);
                // this.goForward();
                this.$router.push(`/result/${result.user._id}`);
            } else {
                notification.error({ message: 'Произошла ошибка при отправке данных ' + `(${result?.message})` });
            }
        },
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setErrors' && !isEmpty(state.errors)) {
                notification.error({
                    message: h('ul', state.errors.map(error => h('li', error))),
                });
            }
        });
        window.steps = this.$store.getters.steps;
        this.$store.dispatch('countShowForward');
    },
};
</script>

<style lang="sass">
//.quiz__back-button, .quiz__forward-button
//  margin-top: 20px
.ant-page-header
    margin-bottom: 20px
.question-description
    margin-bottom: 20px

.description-image
    margin-bottom: 20px

.step-wrapper
    margin-bottom: 20px
    min-height: 300px

</style>
