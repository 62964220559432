import { createStore } from 'vuex';
import quizData from '@/data/start';
import isEmpty from 'lodash/isEmpty';
import validate from 'validate.js';
import { quizTypes as types, validationRules as rules, cacheDataKey, cacheStepKey } from '@/constants';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import castArray from 'lodash/castArray';
import findIndex from 'lodash/findIndex';
import pick from 'lodash/pick';

const saveDataPlugin = store => {
    store.subscribe((mutation, state) => {
        if (mutation.type === 'nextStep') {
            try {
                localStorage.setItem(cacheDataKey, JSON.stringify(state.userData));
            } catch (e) {}
        }
        if (!state.lastStep && !state.firstStep) {
            localStorage.setItem(cacheStepKey, state.stepIndex);
        }
    });
};

const CACHE_DATA = localStorage.getItem(cacheDataKey);
const CACHE_STEP_INDEX = localStorage.getItem(cacheStepKey);
let defaultUserData = {};

if (CACHE_DATA) {
    try {
        defaultUserData = JSON.parse(CACHE_DATA);
    } catch (e) {}
}

export default createStore({
    state() {
        return {
            userData: defaultUserData,
            stepIndex: Number(CACHE_STEP_INDEX),
            errors: [],
            showForward: true,
            lastStep: false,
            firstStep: true,
        };
    },
    mutations: {
        nextStep(state) {
            const { stepIndex } = state;
            const { steps } = quizData;

            if (steps[stepIndex + 1]) {
                state.stepIndex++;
                state.firstStep = false;
            } else {
                state.lastStep = true;
            }
        },
        prevStep(state) {
            const { stepIndex } = state;
            const { steps } = quizData;

            if (steps[stepIndex - 1]) {
                state.stepIndex--;
                state.lastStep = false;
            } else {
                state.firstStep = true;
            }
        },
        setStep(state, index) {
            state.stepIndex = index;
        },
        setErrors(state, list) {
            if (isArray(list)) {
                state.errors = list.filter(Boolean);
            }
        },
        setUserData(state, payload) {
            if (isObject(payload) && !isArray(payload)) {
                state.userData = { ...state.userData, ...payload };
            }
        },
        setShowForward(state, value) {
            if (state.showForward !== value) {
                state.showForward = value;
            }
        },
    },
    actions: {
        goForward({ commit, state, getters, dispatch }) {
            const { userData } = state;

            const fields = getters.stepFields;
            const errors = fields.reduce((acc, fi) => {
                const errs = validate.single(userData[fi], rules[fi]);

                if (!isEmpty(errs)) {
                    acc.push(...errs);
                }
                return acc;
            }, []);

            if (isEmpty(errors)) {
                commit('nextStep');
                dispatch('countShowForward');
            }
            commit('setErrors', errors);
        },
        goBack({ commit, dispatch }) {
            commit('prevStep');
            dispatch('countShowForward');
            commit('setErrors', []);
        },
        goToStep({ commit, getters }, to) {
            if (isNumber(to) && getters.steps[to]) {
                commit('setStep', to);
            } else if (isString(to)) {
                const stepIndex = findIndex(getters.steps, step => {
                    return step.field === to || findIndex(step.options, [ 'field', to ]) !== -1;
                });

                if (stepIndex !== -1) {
                    commit('setStep', stepIndex);
                }
            }
        },
        handleChange({ getters, commit, dispatch }, payload) {
            const { field } = getters.step;

            if (isObject(payload) && !isArray(payload)) {
                commit('setUserData', payload);
            } else {
                commit('setUserData', { [field]: payload });
            }

            dispatch('countShowForward');
        },
        countShowForward({ state, getters, commit }) {
            const { type } = getters.step;
            const fields = getters.stepFields;
            const tests = [
                !type,
                (!isEmpty(fields) && fields.every(fi => Boolean(state.userData[fi]))),
            ];

            commit('setShowForward', tests.some(test => Boolean(test)));
        },
    },
    getters: {
        types: () => types,
        steps: () => quizData.steps,
        step: state => quizData.steps[state.stepIndex],
        stepIndex: state => state.stepIndex,
        stepOptions({ userData }, getters) {
            const step = getters.step;

            if (!step.options && !step.condition) {
                return [];
            }

            if (step.options) {
                return step.options;
            }
            const { condition } = step;
            const conditionValue = userData?.[condition?.field];

            return condition?.[conditionValue] || condition?.default;
        },
        stepFields(state, getters) {
            const { field } = getters.step;
            const fields = getters.stepOptions?.filter(option => option.field)?.map(option => option.field);

            return isEmpty(fields) ? castArray(field) : fields;
        },
        stepValues({ userData }, getters) {
            return pick(userData, getters.stepFields);
        },
        progress(state) {
            const total = quizData.steps.length;
            const { stepIndex } = state;
            let percent = Math.round(100 / (total - 2) * stepIndex - 1);

            if (percent < 0) {
                percent = 0;
            }
            if (percent > 100) {
                percent = 100;
            }

            return percent;
        },
    },
    plugins: [ saveDataPlugin ],
});
