import { createRouter, createWebHistory } from 'vue-router';
import Quiz from '@/views/Quiz';
import Result from '@/views/Result';
import Policy from '@/views/Policy';
import Login from '@/views/Login';
import Administration from '@/views/Administration';
import NotFound from '@/views/NotFound';

const routes = [
    {
        path: '/',
        name: 'Quiz',
        component: Quiz,
    },
    {
        path: '/result/:userId',
        name: 'Result',
        component: Result,
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/administration',
        name: 'Administration',
        component: Administration,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
