<template>
    <div class="select-buttons">
        <template v-for="option in options">
            <a-card
                v-if="option.type === types.card"
                :key="option.value"
                class="select-buttons__option card"
                :class="{active: multiple ? selected?.includes(option.value) : selected === option.value}"
                @click="onClick(option)"
            >
                <div
                    class="select-buttons__icon common-icon"
                    :class="option.icon"
                />
                <p>{{ option.text }}</p>
            </a-card>
            <a-button
                v-else
                :key="option.value"
                class="select-buttons__option button"
                :class="{active: multiple ? selected?.includes(option.value) : selected === option.value}"
                @click="onClick(option)"
            >
                {{ option.text }}
            </a-button>
        </template>
    </div>
</template>

<script>
import { quizTypes as types } from '@/constants';
let unsubscribe;

export default {
    name: 'SelectButtons',
    props: {
        options: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    emits: [ 'change' ],
    data() {
        return {
            selected: this.multiple ? [] : null,
        };
    },
    computed: {
        types: () => types,
    },
    watch: {
        options() {
            this.getDefaultData();
        },
    },
    mounted() {
        this.getDefaultData();
        unsubscribe = this.$store.subscribe(mutation => {
            if (mutation.type === 'nextStep' || mutation.type === 'prevStep') {
                this.getDefaultData();
            }
        });
    },
    unmounted() {
        unsubscribe();
    },
    methods: {
        onClick(option) {
            console.log(option);
            console.log(this.selected);
            if (this.multiple) {
                if (!option.value) {
                    this.selected = [];
                } else if (this.selected?.includes(option.value)) {
                    this.selected = this.selected?.filter(el => el !== option.value);
                } else {
                    this.selected = this.selected?.concat(option.value);
                }
                if (option.not && this.selected?.includes(option.not)) {
                    this.selected = this.selected?.filter(el => el !== option.not);
                }
            } else {
                if (!option.value) {
                    this.selected = null;
                } else {
                    this.selected = option.value;
                }
            }

            this.$store.dispatch('handleChange', this.selected);
        },
        getDefaultData() {
            const { stepValues, stepFields } = this.$store.getters;

            const value = stepValues[stepFields[0]];

            if (value) {
                this.selected = value;
            } else {
                this.selected = this.multiple ? [] : null;
            }
        },
    },
};
</script>

<style lang="sass">
.select-buttons
    display: flex
    flex-wrap: wrap

.select-buttons__option
    width: 300px
    margin-right: 20px
    margin-bottom: 20px
    cursor: pointer

    .ant-card-body
        display: flex
        flex-direction: column
        align-items: center
    &.active
        opacity: 0.5

@media (max-width: 500px)
    .select-buttons
        justify-content: space-between
    .select-buttons__option
        width: calc(50% - 10px)
        margin-right: 0
</style>
