<template>
    <div
        class="stat-card"
        :class="color"
    >
        <div class="stat-card__number">
            <slot name="num" />
        </div>
        <div
            v-if="!hideText"
            class="stat-card__description"
        >
            <slot name="description" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatCard',
    props: {
        hideText: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="sass">
@import '../variables'
.stat-card
    border-radius: 20px
    padding: 20px 40px
    +main-box-shadow('mini')

    &.low, &.warning, &.supranormal
        .stat-card__number
            color: $color-warning

    &.high, &.danger
        .stat-card__number
            color: $color-danger

.stat-card__number
    font-size: 50px
    font-weight: 500

.stat-card__description
    font-size: 16px
</style>
