import isObject from 'lodash/isObject';
import axios from 'axios';
import browserCookies from 'browser-cookies';
import router from '@/router';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { fieldsDict } from '@/constants';

export const macrosCondition = (str, data) => {
    const re = /<%(.+)%>/;

    if (re.test(str) && isObject(data)) {
        const exec = re.exec(str);
        const condition = exec[1];
        const reCondition = /([a-zA-Z0-9_]+)==([a-zA-Z0-9_]+)\s?\?\s?(.+)?\s?:\s?(.+)?/;
        const execCondition = reCondition.exec(condition);
        const [ , field, value, a = '', b = '' ] = execCondition;

        if (field && value) {
            const result = data[field.trim()] === value.trim() ? a : b;

            return str.replace(exec[0], result);
        }
    }
    return str;
};

export const fetcher = (method, url, rest) => {
    const token = browserCookies.get('token');

    return axios.request(url, { headers: { Authorization: `Bearer ${token}` }, method, ...rest })
        .then(({ data }) => data).catch(error => {
            if (error?.response?.status === 401) {
                browserCookies.set('token', '');
                router.replace('/login');
            }
            throw error;
        });
};
fetcher.get = (url, rest) => fetcher('get', url, { params: rest });
fetcher.post = (url, rest) => fetcher('post', url, { data: rest });
fetcher.delete = (url, rest) => fetcher('delete', url, { data: rest });

export function numRound(num) {
    if (typeof num === 'string') {
        num = parseFloat(num);
    }
    if (isNaN(num) || num < 0) {
        return 0;
    }
    return parseFloat(num.toFixed(1));
}

export const nutrientsCount = (calories, sex, type) => {
    const nutrientsEnergy = {
        carbs: 4.1,
        proteins: 4.1,
        fats: 9.29,
    };
    const programManRegular = [ 18, 26, 56 ];
    const programManMuscle = [ 22, 26, 52 ];
    const programManSlim = [ 20, 30, 50 ];
    const programWomanRegular = [ 15, 25, 60 ];
    const programWomanMuscle = [ 20, 25, 55 ];
    const programWomanSlim = [ 20, 25, 55 ];
    const result = program => ({
        energy: {
            proteins: numRound(calories * program[0] / 100),
            fats: numRound(calories * program[1] / 100),
            carbs: numRound(calories * program[2] / 100),
        },
        weight: {
            proteins: numRound((calories * program[0] / 100) / nutrientsEnergy.proteins),
            fats: numRound((calories * program[1] / 100) / nutrientsEnergy.fats),
            carbs: numRound((calories * program[2] / 100) / nutrientsEnergy.carbs),
        },
        program,
        calories,
        type,
    });

    if (type === 'slim') {
        if (sex === 'male') {
            return result(programManSlim);
        }
        return result(programWomanSlim);
    }
    if (type === 'muscle') {
        if (sex === 'male') {
            return result(programManMuscle);
        }
        return result(programWomanMuscle);
    }

    if (sex === 'male') {
        return result(programManRegular);
    }
    return result(programWomanRegular);
};

export const getDictValue = (value, defaultValue) => {
    const dateRe = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3}Z)?/;

    if (isArray(value)) {
        if (isEmpty(value)) {
            return 'нет';
        }
        return value.map(val => fieldsDict[val] || val).join(', ');
    }
    if (dateRe.test(value)) {
        return new Date(value).toLocaleDateString();
    }
    return fieldsDict[value] || value || defaultValue;
};
