import { quizTypes as types } from '@/constants';

export default {
    name: 'Welcome',
    steps: [
        {
            title: 'Привет!',
            description: '<p>Предлагаем тебе ответить на несколько вопросов.</p>' +
                '<p>Часть из них - простые. Для некоторых тебе потребуются инструменты:</p>' +
                '<ul>' +
                '<li>Сантиметр (измерить обхват)</li>' +
                '<li>Линейка или калипер</li>' +
                '<li>Тонометр (измерить давление и пульс)</li>' +
                '</ul>' +
                '<p>С этими инструментами мы получим больше данных, а ты получишь намного более точные результаты.</p>' +
                '<p>Когда будешь готов, нажимай "Вперед".</p>' +
                '<p class="text-grey">* продолжая отвечать на вопросы анкеты, ты соглашаешься с <a class="link-grey" href="/policy">политикой конфиденциальности</a></p>',
        },
        {
            type: types.input,
            title: 'Давай познакомимся',
            options: [
                { label: 'Вас зовут', text: 'Имя Фамилия', required: true, field: 'name' },
                { label: 'Телефон', text: '+79876543211 (Для связи)', required: true, field: 'phone' },
                { label: 'Почта', text: 'Для верификации', required: true, field: 'email' },
            ],
        },
        {
            type: types.selectButtons,
            title: 'Ваш пол',
            field: 'sex',
            options: [
                { type: types.card, icon: 'male', value: 'male', text: 'Мужчина' },
                { type: types.card, icon: 'female', value: 'female', text: 'Женщина' },
            ],
        },
        {
            type: types.input,
            title: 'Данные',
            options: [
                // { field: 'age', type: types.number, text: 'Ваш возраст', label: 'Возраст' },
                { field: 'birthdate', type: types.date, text: 'Дата рождения', label: 'Дата рождения' },
                { field: 'height', text: 'В сантиметрах', type: types.number, label: 'Рост' },
                { field: 'weight', text: 'Например: 50.3', type: types.number, label: 'Вес' },
            ],
        },
        {
            type: types.selectButtons,
            title: 'Тип физической активности',
            field: 'kfa',
            description: '<h3>Выберите подходящий вариант:</h3>' +
                '<ul>' +
                '<li><strong>Минимальная</strong> - "От дивана до холодильника".</li>' +
                '<li><strong>Низкая</strong> - Сидячий образ жизни, кратковременные походы, езда на велосипеде или простые упражнения.</li>' +
                '<li><strong>Средняя</strong> - Физическая/сидячая работа в сочетании с регулярными тренировками 3-5 дней в неделю.</li>' +
                '<li><strong>Высокая</strong> - Активный образ жизни, физическая работа и интенсивная тренировка 6-7 раз в неделю.</li>' +
                '<li><strong>Очень высокая</strong> - "Профессиональный спорт. ' +
                'Ты не пользуешься лифтом и транспортом - только ходьба. Можешь поднять молот Тора"</li>' +
                '</ul>',
            options: [
                { value: 1.2, text: 'минимальная' },
                { value: 1.375, text: 'низкая' },
                { value: 1.55, text: 'средняя' },
                { value: 1.73, text: 'высокая' },
                { value: 1.9, text: 'очень высокая' },
            ],
        },
        {
            type: types.selectButtons,
            title: 'Привычки',
            field: 'habits',
            multiple: true,
            options: [
                { type: types.card, value: 'sugar', icon: 'cake', text: 'Ем сладкое' },
                { type: types.card, value: 'fastfood', icon: 'fastfood', text: 'Ем вредную еду' },
                { type: types.card, value: 'salt', icon: 'salt', text: 'Ем много соленого' },
                { type: types.card, value: 'smoking', icon: 'cigarette', text: 'Курю' },
                { type: types.card, value: 'alcohol', icon: 'alcohol', text: 'Употребляю алкоголь' },
                { type: types.card, value: null, icon: 'nope', text: 'Ничего из перечисленного' },
            ],
        },
        {
            type: types.selectButtons,
            title: 'Цели',
            field: 'goals',
            multiple: true,
            options: [
                { type: types.card, value: 'slim', icon: 'slim', text: 'Снижение веса', not: 'muscle' },
                { type: types.card, value: 'bench', icon: 'bench', text: 'Увеличение работоспособности' },
                { type: types.card, value: 'muscle', icon: 'muscle', text: 'Набор мышечной массы', not: 'slim' },
                { type: types.card, value: 'immunity', icon: 'immunity', text: 'Укрепление иммунитета' },
            ],
        },
        {
            title: 'Отлично, осталось совсем немного!',
            description: '<p>Заполни несколько полей с помощью следующих инструментов:</p>' +
                '<ul>' +
                '<li>Сантиметр (измерить обхват)</li>' +
                '<li>Линейка или калипер</li>' +
                '<li>Тонометр (измерить давление и пульс)</li>' +
                '</ul>' +
                '<p class="text-grey">Если совсем нет возможности заполнить, то нажми кнопку "Пропустить". Но это значит, что результаты будут менее полные.</p>',
            goto: 11,
            gotoLabel: 'Пропустить',
        },
        {
            type: types.input,
            title: 'Толщина кожной складки',
            image: '/images/calipero.jpg',
            description: '<strong>Обрати внимание!</strong> Все значения в миллиметрах.' +
                '<p>(1 сантиметр = 10 миллиметров)</p>',
            options: [
                { text: 'Например: 10', label: 'Грудь', field: 'pectoral_fat', type: types.number },
                { text: 'Например: 11', label: 'Трицепс', field: 'triceps_fat', type: types.number },
                { text: 'Например: 15', label: 'Низ живота', field: 'abdominal_fat', type: types.number },
                { text: 'Например: 12', label: 'Передняя сторона бедра', field: 'thigh_fat', type: types.number },
            ],
            // condition: {
            //     field: 'sex',
            //     male: [
            //         { text: 'Например: 5', label: 'Грудь', field: 'pectoral_fat', type: types.number },
            //         { text: 'Например: 10', label: 'Низ живота', field: 'abdominal_fat', type: types.number },
            //         { text: 'Например: 15', label: 'Передняя сторона бедра', field: 'thigh_fat', type: types.number },
            //     ],
            //     default: [
            //         { text: 'Например: 2', label: 'Трицепс', field: 'triceps_fat', type: types.number },
            //         { text: 'Например: 10', label: 'Низ живота', field: 'abdominal_fat', type: types.number },
            //         { text: 'Например: 12', label: 'Передняя сторона бедра', field: 'thigh_fat', type: types.number },
            //     ],
            // },
        },
        {
            type: types.input,
            title: 'Обхват',
            description: '<strong>Обрати внимание!</strong> Все размеры в сантиметрах',
            options: [
                { text: 'Например: 65', label: 'Талия', field: 'waist_circum', type: types.number },
                { text: 'Например: 97', label: 'Бедра', field: 'thighs_circum', type: types.number },
                { text: 'Например: 96', label: 'Грудь', field: 'chest_circum', type: types.number },
                { text: 'Например: 19', label: 'Запястье', field: 'wrist_circum', type: types.number },
            ],
        },
        {
            type: types.input,
            title: 'Давление и пульс',
            options: [
                { text: 'Например: 127', label: 'Верхнее', field: 'upper_pressure', type: types.number },
                { text: 'Например: 80', label: 'Нижнее', field: 'lower_pressure', type: types.number },
                { text: 'Например: 70', label: 'Пульс', field: 'pulse', type: types.number },
            ],
        },
        {
            type: types.result,
            title: 'Хорошо...',
            description: '<p>Давай проверим, что все данные верны. Если все ок, то жми на кнопку.</p>' +
                '<p>Если нет, то нажми на иконку карандаша и исправь.</p>',
            submit: 'Все хорошо',
        },
        {
            title: 'Класс!',
            description: '<p>На почту, которую ты указал<%sex==female?a:%>, придет письмо с результатом и дальнейшими инструкциями</p>',
            className: 'tada',
        },
    ],
};
