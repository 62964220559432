<template>
    <div class="nutrients-chart">
        <a-typography-title :level="4">
            <slot name="title">
                Обычный день
            </slot>
            <slot name="program">
                (Программа <span class="nobr">"{{ program.program.join(' / ') }}"</span>)
            </slot>
        </a-typography-title>
        <a-row>
            <a-col
                :sm="12"
                :xs="24"
            >
                <vc-donut
                    has-legend
                    class="nutrients-chart__donut-chart"
                    legend-placement="right"
                    :sections="donutsData"
                    :size="150"
                    :thickness="30"
                    text="БЖУ"
                />
            </a-col>
            <a-col
                :sm="12"
                :xs="24"
            >
                <a-typography-title :level="5">
                    Граммовка и энергетическая ценность нутриентов
                </a-typography-title>
                <ul>
                    <li>Белки: {{ program.energy.proteins }} ккал / {{ program.weight.proteins }} гр.</li>
                    <li>Жиры: {{ program.energy.proteins }} ккал / {{ program.weight.proteins }} гр.</li>
                    <li>Углеводы: {{ program.energy.proteins }} ккал / {{ program.weight.proteins }} гр.</li>
                </ul>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
    name: 'NutrientsChart',
    functional: true,
    props: {
        program: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const labels = [ 'Белки', 'Жиры', 'Углеводы' ];
        const donutsData = props.program.program.map((value, idx) => ({ value, label: labels[idx] + ` - ${value}%` }));

        return {
            donutsData,
        };
    },
};
</script>

<style lang="sass">
.nutrients-chart
    margin-bottom: 20px

.nutrients-chart__donut-chart
    justify-content: flex-start
    margin-bottom: 20px

</style>
