<template>
    <div class="administration">
        <h1>Данные пользователей</h1>
        <a-list
            item-layout="horizontal"
            :data-source="usersList"
        >
            <template #renderItem="{ item }">
                <a-list-item
                    class="user-list-item"
                >
                    <template #actions>
                        <a @click="openUserPopup(item)">Данные</a>
                    </template>
                    <a-list-item-meta
                        description="user"
                    >
                        <template #title>
                            {{ item.name }}
                        </template>
                        <template #avatar>
                            <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                        </template>
                    </a-list-item-meta>
                </a-list-item>
            </template>
        </a-list>

        <a-modal
            v-model:visible="modalIsVisible"
            class="data-modal"
            :title="'Данные польлзователя ' + selectedUser.name"
        >
            <!--TODO: fix modal buttons-->
            <template v-if="selectedUser">
                <router-link :to="'/result/' + selectedUser._id">
                    Ссылка на результат
                    <FileDoneOutlined />
                </router-link>
                <div
                    v-for="(group, groupKey) in userFieldsOrder"
                    :key="groupKey"
                    class="data-modal__group"
                >
                    <h3>{{ getValue(groupKey) }}</h3>
                    <p
                        v-for="key in group"
                        :key="key"
                    >
                        <strong>{{ getValue(key) }}</strong>: {{ getValue(selectedUser[key], 'нет') }} {{ getUnits(key) }}
                    </p>
                </div>
            </template>

            <template #footer>
                <a-button
                    key="back"
                    @click="modalIsVisible = false"
                >
                    Закрыть
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import browserCookies from 'browser-cookies';
import { fetcher, getDictValue } from '@/helpers';
import isEmpty from 'lodash/isEmpty';
import { measureUnits, userFieldsOrder } from '@/constants';
import { FileDoneOutlined } from '@ant-design/icons-vue';

export default {
    name: 'Administration',
    components: { FileDoneOutlined },
    data() {
        return {
            usersList: [],
            selectedUser: '',
            modalIsVisible: false,
        };
    },
    computed: {
        userFieldsOrder: () => userFieldsOrder,
    },
    async created() {
        const token = browserCookies.get('token');

        if (!token) {
            await this.$router.push('/login');
        } else {
            const users = await fetcher.get('/api/users/list');

            if (!isEmpty(users)) {
                this.usersList = users.filter(user => !user?.roles.includes('admin'));
            }
        }
    },
    methods: {
        openUserPopup(user) {
            this.selectedUser = user;
            this.modalIsVisible = true;
        },
        getValue: getDictValue,
        getUnits(field) {
            return measureUnits[field] || '';
        },
    },
};
</script>

<style lang="sass">
@import '../variables'

.user-list-item
    &:hover
        +main-box-shadow('mini')

.data-modal
    min-width: 60%
    .ant-modal-body > a
        margin-bottom: 20px
        display: inline-block

.data-modal__group
    margin-bottom: 30px

    h3
        margin-bottom: 20px
</style>
